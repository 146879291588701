<template>
  <div>
    <loading :active.sync="is_call_api"></loading>
    <v-row justify="center">
      <v-dialog
        v-model="dialogStudent"
        max-width="1400px"
        scrollable
        persistent
        :retain-focus="false"
      >
        <v-card>
          <v-card-title>
            <v-col cols="11">
              <span class="headline" v-if="type_form === 'add'"
                >Add High Score Student</span
              >
              <span class="headline" v-else>Edit High Score Student</span>
            </v-col>
            <v-col cols="1">
              <v-icon @click="dialogStudent = false" right>mdi-close</v-icon>
            </v-col>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-row>
                    <v-col cols="2" class="text-right p-0 m-0"
                      ><label class="text-h6">Nhập tên học sinh:</label></v-col
                    >
                    <v-col cols="8" class="p-0 m-0 ml-4"
                      ><v-text-field
                        dense
                        v-model="name_input"
                        filled
                      ></v-text-field
                    ></v-col>
                  </v-row>
                  <v-row class="mt-4">
                    <v-col cols="2" class="text-right p-0 m-0"
                      ><label class="text-h6">Avatar:</label></v-col
                    >
                    <v-col cols="8" class="p-0 m-0 ml-4">
                      <ImageCompress :done="getFileAvatar"></ImageCompress>
                      <template v-if="avatar_data != null">
                        <b-img
                          fluid
                          thumbnail
                          :rounded="true"
                          :src="avatar_data"
                        ></b-img>
                      </template>
                    </v-col>
                  </v-row>
                  <v-row class="mt-8">
                    <v-col cols="2" class="text-right p-0 m-0"
                      ><label class="text-h6">Địa chỉ:</label></v-col
                    >
                    <v-col cols="8" class="p-0 m-0 ml-4"
                      ><v-text-field
                        dense
                        filled
                        v-model="address_input"
                      ></v-text-field
                    ></v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="2" class="text-right p-0 m-0"
                      ><label class="text-h6">Nhập điểm ielts:</label></v-col
                    >
                    <v-col cols="8" class="p-0 m-0 ml-4">
                      <v-row>
                        <v-col cols="3" class="d-flex">
                          <label class="text-h6 mt-1 mr-2">Speaking:</label>
                          <v-text-field
                            filled
                            dense
                            min="0"
                            max="9"
                            type="number"
                            v-model="speaking_input"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="3" class="d-flex">
                          <label class="text-h6 mt-1 mr-2">Writing:</label>
                          <v-text-field
                            filled
                            dense
                            min="0"
                            max="9"
                            type="number"
                            v-model="writing_input"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="3" class="d-flex">
                          <label class="text-h6 mt-1 mr-2">Reading:</label>
                          <v-text-field
                            filled
                            dense
                            min="0"
                            max="9"
                            type="number"
                            v-model="reading_input"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="3" class="d-flex">
                          <label class="text-h6 mt-1 mr-2">Listening:</label>
                          <v-text-field
                            filled
                            dense
                            min="0"
                            max="9"
                            type="number"
                            v-model="listening_input"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="3" class="d-flex pt-0 pb-0">
                          <label class="text-h6 mt-1 mr-2">Overall:</label>
                          <v-text-field
                            filled
                            dense
                            min="0"
                            max="9"
                            type="number"
                            v-model="overall_input"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row class="mt-4">
                    <v-col cols="2" class="text-right p-0 m-0"
                      ><label class="text-h6">Hiện thị trên home:</label></v-col
                    >
                    <v-col cols="8" class="ml-4 p-0">
                      <v-switch
                        class="mt-0 pt-0 ml-2"
                        v-model="is_apply_home"
                        :label="is_apply_home ? 'Yes' : 'No'"
                      ></v-switch>
                    </v-col>
                  </v-row>
                  <v-row class="mt-4">
                    <v-col cols="2" class="text-right p-0 m-0"
                      ><label class="text-h6"
                        >Xếp lên đầu danh sách:</label
                      ></v-col
                    >
                    <v-col cols="8" class="ml-4 p-0">
                      <v-switch
                        class="mt-0 pt-0 ml-2"
                        v-model="is_top_of_list"
                        :label="is_top_of_list ? 'Yes' : 'No'"
                      ></v-switch>
                    </v-col>
                  </v-row>
                  <v-row class="mt-4">
                    <v-col cols="2" class="text-right p-0 m-0"
                      ><label class="text-h6">Chọn programme:</label></v-col
                    >
                    <v-col cols="8" class="ml-4 p-0">
                      <v-autocomplete
                        v-model="programme_id"
                        :items="programmes"
                        item-value="id"
                        item-text="name.vi"
                        dense
                        filled
                      >
                      </v-autocomplete>
                    </v-col>
                  </v-row>
                  <v-row class="mt-4">
                    <v-col cols="2" class="text-right p-0 m-0"
                      ><label class="text-h6">Feedback:</label></v-col
                    >
                    <v-col cols="8" class="ml-4 p-0">
                      <Editor v-model="feedback_input"></Editor>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <button
              class="btn btn-light-success text-uppercase mr-2"
              v-if="type_form === 'add'"
              @click="btnAddOrEditStudent(1)"
            >
              Add
            </button>
            <button
              class="btn btn-light-success text-uppercase mr-2"
              v-if="type_form === 'update'"
              @click="btnAddOrEditStudent(2)"
            >
              Update
            </button>
            <button
              class="btn text-uppercase mr-2"
              style="color: #f64e60"
              @click="dialogStudent = false"
            >
              Close
            </button>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import "vue-loading-overlay/dist/vue-loading.css";
import ImageCompress from "@/components/image/ImageCompress";
import ApiService from "@/service/api.service";
export default {
  name: "FormHighScrore",
  components: {
    ImageCompress,
    Loading: () => import("vue-loading-overlay"),
    Editor: () => import("@/components/ckeditor5/Editor.vue"),
  },
  props: {
    programmes: {
      type: Array,
      default: () => [],
    },
    show_dialog: {
      type: Boolean,
      default: false,
    },
    type_form: {
      type: String,
      default: "",
    },
    detail_high_score: {},
  },
  data() {
    return {
      is_call_api: false,
      name_input: "",
      avatar_data: null,
      avatar_file: null,
      address_input: "",
      programme_id: null,
      speaking_input: null,
      reading_input: null,
      writing_input: null,
      listening_input: null,
      overall_input: null,
      student_id: null,
      is_apply_home: false,
      is_top_of_list: false,
      feedback_input: null,
    };
  },
  computed: {
    dialogStudent: {
      get() {
        return this.show_dialog;
      },
      set(val) {
        this.$emit("setDialog", val);
      },
    },
  },
  watch: {
    dialogStudent(val) {
      if (val) {
        this.setDataForm();
      }
    },
  },
  methods: {
    setDataForm() {
      if (this.type_form === "add") {
        this.name_input = "";
        this.avatar_file = null;
        this.avatar_data = null;
        this.address_input = "";
        this.programme_id = null;
        this.speaking_input = null;
        this.reading_input = null;
        this.writing_input = null;
        this.listening_input = null;
        this.overall_input = null;
        this.student_id = null;
        this.is_apply_home = false;
        this.is_top_of_list = false;
        this.feedback_input = "";
      } else {
        this.student_id = this.detail_high_score.id;
        this.name_input = this.detail_high_score.name;
        this.address_input = this.detail_high_score.address;
        this.avatar_data = this.detail_high_score.avatar;
        this.avatar_file = null;
        this.programme_id =
          this.detail_high_score.programme != null
            ? this.detail_high_score.programme.id
            : null;
        let ielts_score =
          this.detail_high_score.ielts_score == null
            ? null
            : JSON.parse(this.detail_high_score.ielts_score);
        this.speaking_input =
          this.detail_high_score.ielts_score == null
            ? null
            : ielts_score.speaking;
        this.reading_input =
          this.detail_high_score.ielts_score == null
            ? null
            : ielts_score.writing;
        this.writing_input =
          this.detail_high_score.ielts_score == null
            ? null
            : ielts_score.reading;
        this.listening_input =
          this.detail_high_score.ielts_score == null
            ? null
            : ielts_score.listening;
        this.overall_input =
          this.detail_high_score.ielts_score == null
            ? null
            : ielts_score.overall;
        this.is_apply_home = this.detail_high_score.is_apply_home === 1;
        this.is_top_of_list = this.detail_high_score.is_top_of_list === 1;
        this.feedback_input =
          this.detail_high_score.feedback == null
            ? ""
            : this.detail_high_score.feedback;
      }
    },
    getFileAvatar(obj) {
      this.avatar_data = obj.blob;
      this.avatar_file = obj.file;
    },
    validateData() {
      let flat = true;
      // let vm = this;
      if (this.name_input === "" || this.name_input == null) {
        this.$toasted.error("Chưa nhập tên học sinh !!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
        flat = false;
      }
      if (this.programme_id == null) {
        this.$toasted.error("Chưa chọn programme !!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
        flat = false;
      }
      if (this.speaking_input === "" || this.speaking_input == null) {
        this.$toasted.error("Chưa nhập điểm speaking !!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
        flat = false;
      }
      if (this.reading_input === "" || this.reading_input == null) {
        this.$toasted.error("Chưa nhập điểm reading !!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
        flat = false;
      }
      if (this.writing_input === "" || this.writing_input == null) {
        this.$toasted.error("Chưa nhập điểm writing !!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
        flat = false;
      }
      if (this.listening_input === "" || this.listening_input == null) {
        this.$toasted.error("Chưa nhập điểm listening !!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
        flat = false;
      }
      if (this.overall_input === "" || this.overall_input == null) {
        this.$toasted.error("Chưa nhập điểm overall !!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
        flat = false;
      }
      return flat;
    },
    btnAddOrEditStudent(type) {
      let validateQues = this.validateData();
      if (!validateQues) {
        return;
      }
      let vm = this;
      let ielts_score = {
        speaking: this.speaking_input,
        writing: this.writing_input,
        reading: this.reading_input,
        listening: this.listening_input,
        overall: this.overall_input,
      };
      let formData = new FormData();
      formData.append("name", this.name_input);
      formData.append("address", this.address_input);
      formData.append("programme_id", this.programme_id);
      formData.append("is_apply_home", this.is_apply_home ? 1 : 0);
      formData.append("ielts_score", JSON.stringify(ielts_score));
      formData.append("is_top_of_list", this.is_top_of_list ? 1 : 0);
      if (this.feedback_input != null && this.feedback_input !== "")
        formData.append("feedback", this.feedback_input);
      if (this.avatar_file != null) formData.append("avatar", this.avatar_file);
      this.is_call_api = true;
      if (type === 1) {
        ApiService.post("prep-app/high-sroce-student", formData)
          .then(function (res) {
            console.log(res.status);
            if (res.status === 200) {
              vm.$toasted.success("Thêm thành công !!", {
                theme: "toasted-primary",
                position: "top-right",
                duration: 4000,
              });
              vm.$emit("resetDataStudent");
              vm.is_call_api = false;
              vm.dialogStudent = false;
            }
          })
          .catch(function (error) {
            vm.is_call_api = false;
            if (error.response.status === 422) {
              let data_response_error =
                error.response.data.error.message.split(".<br />");
              vm.showError(data_response_error);
            }
          });
      } else {
        formData.append("_method", "PUT");
        ApiService.post(
          "prep-app/high-sroce-student/" + vm.student_id,
          formData
        )
          .then(function (res) {
            console.log(res.status);
            if (res.status === 200) {
              vm.$toasted.success("Sửa thành công !!", {
                theme: "toasted-primary",
                position: "top-right",
                duration: 4000,
              });
              vm.$emit("resetDataStudent");
              vm.is_call_api = false;
              vm.dialogStudent = false;
            }
          })
          .catch(function (error) {
            vm.is_call_api = false;
            if (error.response.status === 422) {
              let data_response_error =
                error.response.data.error.message.split(".<br />");
              vm.showError(data_response_error);
            }
          });
      }
    },
    showError(data_error) {
      for (let i = 0; i < data_error.length; i++) {
        this.$toasted.error(data_error[i] + " !!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
      }
    },
  },
};
</script>

<style scoped></style>
