<template>
  <v-app>
    <loading :active.sync="is_call_api"></loading>
    <div style="background-color: rgb(243 246 249)">
      <h1 class="mt-5 mb-5" style="color: #3f4254">
        Danh sách học sinh điểm cao
      </h1>
    </div>
    <div class="pt-5 pl-5 pr-5">
      <v-btn
        rounded
        color="primary"
        class="mb-5"
        x-small
        large
        @click="openDiaLogStudent"
        v-if="canAccess('student-create')"
      >
        <v-icon color="white" dark>mdi-plus</v-icon
        ><span style="color: white">Thêm học viên điểm cao</span>
      </v-btn>
      <div>
        <v-row>
          <v-col cols="12">
            <div class="table-responsive">
              <table
                class="table datatable-bordered table-head-custom table-vertical-center table-head-bg"
              >
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Avatar</th>
                    <th scope="col">Name</th>
                    <th scope="col">Programme</th>
                    <th scope="col">Áp dụng home</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-for="(student, i) in high_score_students">
                    <tr :key="i">
                      <td scope="row">{{ i + 1 }}</td>
                      <td class="">
                        <v-img
                          :src="student.avatar"
                          max-width="100"
                          max-height="70"
                          class="rounded-lg"
                        ></v-img>
                      </td>
                      <td>
                        <p
                          class="text-dark-75 font-weight-bolder d-block font-size-lg"
                        >
                          {{ student.name }}
                        </p>
                      </td>
                      <td>
                        <template v-if="student.programme != null">
                          <p
                            class="text-dark-75 font-weight-bolder d-block font-size-lg"
                            style="max-width: 300px"
                          >
                            {{ student.programme.name.vi }}
                          </p>
                        </template>
                      </td>
                      <td>
                        <p
                          class="text-dark-75 font-weight-bolder d-block font-size-lg light-blue--text"
                          v-if="student.is_apply_home === 1"
                        >
                          Có
                        </p>
                        <p
                          class="text-dark-75 font-weight-bolder d-block font-size-lg yellow--text text--darken-2"
                          v-if="student.is_apply_home === 0"
                        >
                          Không
                        </p>
                      </td>
                      <td>
                        <div style="display: flex">
                          <v-tooltip left>
                            <template v-slot:activator="{ on, attrs }">
                              <button
                                class="btn btn-icon btn-light-success btn-sm"
                                v-bind="attrs"
                                v-on="on"
                                @click="editStudent(i)"
                                v-if="canAccess('student-update')"
                              >
                                <span
                                  class="svg-icon svg-icon-md svg-icon-primary"
                                >
                                  <v-icon color=""
                                    >mdi-square-edit-outline</v-icon
                                  >
                                </span>
                              </button>
                            </template>
                            <span>Edit</span>
                          </v-tooltip>
                          <v-tooltip right>
                            <template v-slot:activator="{ on, attrs }">
                              <button
                                class="btn btn-icon btn-light-danger btn-sm ml-4"
                                v-bind="attrs"
                                v-on="on"
                                @click="deleteStudent(student.id)"
                                v-if="canAccess('student-delete')"
                              >
                                <span
                                  class="svg-icon svg-icon-md svg-icon-primary"
                                >
                                  <v-icon color="">mdi-delete</v-icon>
                                </span>
                              </button>
                            </template>
                            <span>Delete</span>
                          </v-tooltip>
                        </div>
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </v-col>
        </v-row>
      </div>
    </div>

    <FormHighScrore
      :type_form="type_form"
      :show_dialog="dialogStudent"
      :programmes="programmes"
      :detail_high_score="detail_high_score"
      @setDialog="(e) => (dialogStudent = e)"
      @resetDataStudent="resetDataStudent"
    />
  </v-app>
</template>

<script>
import "vue-loading-overlay/dist/vue-loading.css";
import ApiService from "@/service/api.service";
import FormHighScrore from "@/view/pages/students/modal/FormHighScrore";
import Swal from "sweetalert2";
export default {
  name: "HighScore",
  components: {
    FormHighScrore,
    Loading: () => import("vue-loading-overlay"),
  },
  data() {
    return {
      is_call_api: false,
      high_score_students: [],
      dialogStudent: false,
      type_form: null,
      programmes: [],
      detail_high_score: {},
    };
  },
  created() {
    this.getHighScoreStudents();
    this.getProgrammeLimit();
  },
  methods: {
    canAccess(permission) {
      return this.$utils.canAccess(permission);
    },
    async getHighScoreStudents() {
      let vm = this;
      try {
        vm.is_call_api = true;
        let res = await ApiService.get("prep-app/high-sroce-student");
        if (res.status === 200) {
          vm.high_score_students = res.data;
          vm.is_call_api = false;
        }
      } catch (error) {
        vm.is_call_api = false;
        console.log(error);
      }
    },
    getProgrammeLimit() {
      let vm = this;
      ApiService.get("prep-app/programme/limit")
        .then(function (res) {
          if (res.status === 200) {
            vm.programmes = res.data;
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    async resetDataStudent() {
      this.is_call_api = true;
      await this.getHighScoreStudents();
      this.is_call_api = false;
    },
    openDiaLogStudent() {
      this.dialogStudent = true;
      this.type_form = "add";
    },
    editStudent(index) {
      this.detail_high_score = this.high_score_students[index];
      this.type_form = "update";
      this.dialogStudent = true;
    },
    deleteStudent(student_id) {
      let vm = this;
      Swal.fire({
        title: "Are you sure you want to delete?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value) {
          ApiService.delete("prep-app/high-sroce-student/" + student_id)
            .then(function (res) {
              if (res.status === 200) {
                vm.$toasted.success("Delete successfully!!", {
                  theme: "toasted-primary",
                  position: "top-right",
                  duration: 4000,
                });
                vm.resetDataStudent();
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        }
      });
    },
  },
};
</script>

<style lang="css" scoped>
.table.table-head-bg thead th {
  background-color: #2196f3eb !important;
  color: white !important;
}
</style>
